import React from "react";
import Log from "../utils/log.js";
import { Layout, Button, Radio, message } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchCommissions, fetchCampaigns, requestAirtimeDataReceivingCountries } from "../actions";

const { Content } = Layout;
const TAG = "SmartMyRewards";

const buttonStyle = {
  backgroundColor: "rgb(41, 108, 187)",
  borderColor: "rgb(41, 108, 187)",
  boxSizing: "border-box",
  color: "white",
  border: "1px solid rgb(41, 108, 187)",
  marginRight: 8,
};

const radioStyle = {
  display: "in-line",
  height: "30px",
  lineHeight: "30px",
};

class MyRewards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commissions: {},
      campaigns: {},
      redeemOption: "",
      showMessage: false,
    };
  }

  requestCommissionsAndCampaigns(accessToken) {
    this.props.fetchCommissions(accessToken);
    this.props.fetchCampaigns(accessToken);
  }

  requestAirtimeReceivingCountries() {

    const sendingCountry = this.props.countryReducer.sendingCountry;

    if (!sendingCountry) {
      this.props.history.goBack();
    }

    this.props.requestAirtimeDataReceivingCountries(
      sendingCountry.attributes.alpha_2_code
    );
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const accessToken = this.props.authReducer.accessToken;
    this.requestCommissionsAndCampaigns(accessToken);
    this.requestAirtimeReceivingCountries();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      message.error("Error loading referrals", 5);
    }

    if (
      prevProps.referralsReducer.commissions !==
      this.props.referralsReducer.commissions
    ) {
      this.setState({
        commissions: this.props.referralsReducer.commissions,
      });
    }

    if (
      prevProps.referralsReducer.campaigns !==
      this.props.referralsReducer.campaigns
    ) {
      this.setState({
        campaigns: this.props.referralsReducer.campaigns,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      showMessage: false,
      redeemOption: e.target.value,
    });
  };

  onProceed() {
    if (this.state.redeemOption === "airtimeData") {
      this.props.history.push("/my-rewards/airtime-data/recipient-details");
    } else if (this.state.redeemOption === "voucher") {
      this.props.history.push("/my-rewards/voucher/details");
    } else {
      this.setState({
        showMessage: true,
      });
    }
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div className="signup-body">
            <h2 style={{ fontFamily: "Lato" }}>My Rewards</h2>
            <div>
              <Radio.Group
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  margin: "20px 0",
                }}
                onChange={this.onChange}
                value={this.state.redeemOption}
              >
                <Radio style={radioStyle} value={"airtimeData"}>
                  Airtime/Data Bundle
                </Radio>
                <Radio style={radioStyle} value={"voucher"}>
                  Voucher for free money transfer
                </Radio>
              </Radio.Group>
              <br />
              {this.state.showMessage && (
                <p style={{ color: "red" }}>Please select a redeem option</p>
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "#154989",
                      boxSizing: "border-box",
                      color: "white",
                      margin: "8px",
                    }}
                    type="primary"
                    onClick={() => this.onProceed()}
                  >
                    Next
                  </Button>
                </div>
              </div>
              <br />
            </div>
            <div style={{ width: "100%", padding: "20px", textAlign: "center" }}>
                <b style={{ width: "100%", color: "black", fontWeight: "bold", fontSize: 12 }}>
                  Please note that charges apply for airtime purchases when converting rewards into airtime.
                </b>
              </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchCommissions,
      fetchCampaigns,
      requestAirtimeDataReceivingCountries,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRewards);
